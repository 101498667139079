<template>
    <TheHeader></TheHeader>
	<div id="container" class="container container--fit">
		<div id="sub" class="sub leisure">
			<!-- category -->
			<section class="category">
				<div class="box">
					<ul class="category__list">
						<li>
							<i class="icon icon-acti1"></i>
							<span>액티비티</span>
						</li>
						<li>
							<i class="icon icon-acti2"></i>
							<span>테마파크</span>
						</li>
						<li>
							<i class="icon icon-acti3"></i>
							<span>공연·전시</span>
						</li>
						<li>
							<i class="icon icon-acti4"></i>
							<span>워터파크·<br>스파</span>
						</li>
						<li>
							<i class="icon icon-acti5"></i>
							<span>축제·행사</span>
						</li>
						<li>
							<i class="icon icon-acti6"></i>
							<span>교통</span>
						</li>
						<li>
							<i class="icon icon-acti7"></i>
							<span>테마A</span>
						</li>
						<li>
							<i class="icon icon-acti8"></i>
							<span>테마B</span>
						</li>
					</ul>
				</div>
			</section>
			<!-- content -->
			<section class="content">
				<!-- 새로운 레저·티켓 -->
				<div class="item">
					<h2 class="title">
						새로운 레저·티켓
						<a href="#" class="btn-more">
							MORE
						</a>
					</h2>
					<div class="item__body">
						<div class="carousel">
							<!-- [REST] 현재 기준 가까운 상품 10개 호출 -->
							<div class="carousel__slider">
								<swiper :slides-per-view="1.4" :space-between="16">
									<swiper-slide>
										<router-link to="/rooms/list" class="carousel__link">
											<div class="carousel__image">
												<img src="@/assets/images/tempsquare01.jpg" alt="역삼 벤">
											</div>
											<div class="carousel__info">
												<div class="carousel__slogan info-slogan">
													불금은 다같이 맥주파티!
												</div>
												<h3 class="carousel__title info-title">
													[서울] 한강 맥주파티
												</h3>
												<div class="carousel__grad info-grad">
													<span class="carousel__icon info-icon">
														<i class="icon icon-star"></i>
													</span>
													<span class="carousel__gradnum info-gradnum">
														9.7
													</span>
													<span class="carousel__gradcounter info-gradcounter">
													2,400
												</span>
												</div>
												<div class="carousel__price">
													<span class="carousel__saleprice">
														28%
													</span>
													<span class="carousel__currentprice">
														<strong>49,800</strong>원
													</span>
												</div>
											</div>
										</router-link>
									</swiper-slide>
									<swiper-slide>
										<a href="#" class="carousel__link">
											<div class="carousel__image">
												<img src="@/assets/images/tempsquare01.jpg" alt="역삼 벤">
											</div>
											<div class="carousel__info">
												<div class="carousel__slogan info-slogan">
													제주의 낭만을 즐기며
												</div>
												<h3 class="carousel__title info-title">
													[제주] 차박 대여 세트
												</h3>
												<div class="carousel__grad info-grad">
													<span class="carousel__icon info-icon">
														<i class="icon icon-star"></i>
													</span>
													<span class="carousel__gradnum info-gradnum">
														9.7
													</span>
													<span class="carousel__gradcounter info-gradcounter">
													2,400
												</span>
												</div>
												<div class="carousel__price">
													<span class="carousel__saleprice">
														28%
													</span>
													<span class="carousel__currentprice">
														<strong>49,800</strong>원
													</span>
												</div>
											</div>
										</a>
									</swiper-slide>
								</swiper>
							</div>
						</div>
					</div>
				</div>
				<!-- 이벤트 -->
				<div class="item">
					<div class="banner bg">
						<div class="banner__box">
							<div class="banner__navs">
								<button type="button" class="banner__nav banner__nav--prev">
									<i class="icon icon-left">이전</i>
								</button>
								<button type="button" class="banner__nav banner__nav--next">
									<i class="icon icon-right">다음</i>
								</button>
							</div>
						</div>
						<!-- [D] 뱃지 있을 시 추가 -->
						<div class="banner__badge badge badge--notice">
							<span class="badge__text">
								이벤트
							</span>
						</div>
						<!-- [REST] 이벤트 노출, 관리자에서 설정 -->
						<div class="banner__slider">
							<swiper :navigation="{ nextEl : '.banner__nav--next', prevEl : '.banner__nav--prev'}">
								<swiper-slide>
									<a href="#" class="banner__link">
										<div class="banner__image">
											<img src="@/assets/images/tempsquare02.jpg" alt="temp10">
										</div>
										<div class="banner__info">
											<h3 class="banner__title">
												비발디파크 리프트권 단독특가
											</h3>
											<p class="banner__desc">
												기간 한정 최대 57% 할인
											</p>
										</div>
									</a>
								</swiper-slide>
								<swiper-slide>
									<a href="#" class="banner__link">
										<div class="banner__image">
											<img src="@/assets/images/tempsquare02.jpg" alt="temp10">
										</div>
										<div class="banner__info">
											<h3 class="banner__title">
												신라스테이 호텔 브랜드 특가 이벤트
											</h3>
											<p class="banner__desc">
												MD 강력추천 가을 숙소
											</p>
										</div>
									</a>
								</swiper-slide>
							</swiper>
						</div>
					</div>
				</div>
				<!-- 주간 인기 레저·티켓 -->
				<div class="item">
					<h2 class="title">
						주간 인기 레저·티켓
						<a href="#" class="btn-more">
							MORE
						</a>
					</h2>
					<div class="item__body">
						<!-- [REST] 인기 숙박 노출, 최근 7일 이내 예약 많은 순 노출 (최대 10개) -->
						<div class="carousel">
							<swiper :slides-per-view="1.4" :space-between="16">
								<swiper-slide>
									<a href="#" class="carousel__link">
										<div class="carousel__image">
											<img src="@/assets/images/tempsquare01.jpg" alt="역삼 벤">
										</div>
										<div class="carousel__info">
											<div class="carousel__slogan info-slogan">
												가족과 함께 즐기기 좋은
											</div>
											<h3 class="carousel__title info-title">
												[강원] 대관령 양떼목장
											</h3>
											<div class="carousel__grad info-grad">
													<span class="carousel__icon info-icon">
														<i class="icon icon-star"></i>
													</span>
												<span class="carousel__gradnum info-gradnum">
														9.7
													</span>
												<span class="carousel__gradcounter info-gradcounter">
													2,400
												</span>
											</div>
											<div class="carousel__price">
												<span class="carousel__saleprice">
													28%
												</span>
												<span class="carousel__currentprice">
													<strong>49,800</strong>원
												</span>
											</div>
										</div>
									</a>
								</swiper-slide>
								<swiper-slide>
									<a href="#" class="carousel__link">
										<div class="carousel__image">
											<img src="@/assets/images/tempsquare01.jpg" alt="역삼 벤">
										</div>
										<div class="carousel__info">
											<div class="carousel__slogan info-slogan">
												환상적인 루프탑이 있어요
											</div>
											<h3 class="carousel__title info-title">
												역삼 벤
											</h3>
											<div class="carousel__grad info-grad">
													<span class="carousel__icon info-icon">
														<i class="icon icon-star"></i>
													</span>
												<span class="carousel__gradnum info-gradnum">
														9.7
													</span>
												<span class="carousel__gradcounter info-gradcounter">
													2,400
												</span>
											</div>
											<div class="carousel__price">
												<span class="carousel__saleprice">
														28%
													</span>
												<span class="carousel__currentprice">
														<strong>49,800</strong>원
													</span>
											</div>
										</div>
									</a>
								</swiper-slide>
							</swiper>
						</div>
					</div>
				</div>
				<!-- 지역별 추천 숙소 -->
				<div class="item">
					<h2 class="title">
						가장 놀기 좋은 지역은?
					</h2>
					<div class="item__body">
						<div class="menus menus--small">
							<div class="menus__wrap">
								<!-- [D] 활성화된 지역에 active 클래스 추가 -->
								<!-- [REST] 지역 분류 관리자 페이지에서 설정 -->
								<div class="menus__box">
									<div class="menus__text">서울</div>
									<div class="menus__text active">부산</div>
									<div class="menus__text">제주</div>
									<div class="menus__text">강원</div>
									<div class="menus__text">경북</div>
									<div class="menus__text">경남</div>
									<div class="menus__text">전남</div>
									<div class="menus__text">전남</div>
									<div class="menus__text">전남</div>
									<div class="menus__text">전남</div>
								</div>
							</div>
						</div>
						<div class="carousel carousel--left">
							<!-- [REST] 지역별 숙소 호출 -->
							<div class="carousel__slider" data-slider="carousel">
								<swiper :slides-per-view="1.4" :space-between="16">
									<swiper-slide>
										<a href="#" class="carousel__link">
											<div class="carousel__image">
												<img src="@/assets/images/tempsquare01.jpg" alt="역삼 벤">
											</div>
											<div class="carousel__info">
												<div class="carousel__slogan info-slogan">
													환상적인 예술작품이 가득한
												</div>
												<h3 class="carousel__title info-title">
													[부산] 뮤지엄 DAH
												</h3>
												<div class="carousel__grad info-grad">
													<span class="carousel__icon info-icon">
														<i class="icon icon-star"></i>
													</span>
													<span class="carousel__gradnum info-gradnum">
														9.7
													</span>
													<span class="carousel__gradcounter info-gradcounter">
													2,400
												</span>
												</div>
												<div class="carousel__price">
													<span class="carousel__saleprice">
														28%
													</span>
													<span class="carousel__currentprice">
														<strong>49,800</strong>원
													</span>
												</div>
											</div>
										</a>
									</swiper-slide>
									<swiper-slide>
										<a href="#" class="carousel__link">
											<div class="carousel__image">
												<img src="@/assets/images/tempsquare01.jpg" alt="역삼 벤">
											</div>
											<div class="carousel__info">
												<div class="carousel__slogan info-slogan">
													환상적인 루프탑이 있어요
												</div>
												<h3 class="carousel__title info-title">
													역삼 벤
												</h3>
												<div class="carousel__grad info-grad">
													<span class="carousel__icon info-icon">
														<i class="icon icon-star"></i>
													</span>
													<span class="carousel__gradnum info-gradnum">
														9.7
													</span>
													<span class="carousel__gradcounter info-gradcounter">
													2,400
												</span>
												</div>
												<div class="carousel__price">
													<span class="carousel__saleprice">
														28%
													</span>
													<span class="carousel__currentprice">
														<strong>49,800</strong>원
													</span>
												</div>
											</div>
										</a>
									</swiper-slide>
								</swiper>
							</div>
						</div>
					</div>
				</div>
				<!-- 헬로스테이 기획전 -->
				<div class="item">
					<h2 class="title">
						기획전
						<a href="#" class="btn-more">
							MORE
						</a>
					</h2>
					<div class="item__body">
						<div class="card">
							<!-- [D] 뱃지 있을 시 추가 -->
							<div class="card__badge badge badge--plan">
								<span class="badge__text">
									기획전
								</span>
							</div>
							<!-- [REST] 기획전 노출, 관리자 페이지에서 설정 -->
							<div class="card__slider">
								<swiper :scrollbar="{ el : '.progress__bar--1' }">
									<swiper-slide>
										<a href="#" class="card__link">
											<div class="card__image">
												<img src="@/assets/images/temp08.jpg" alt="힐링을 선물받다 펜캉스 고고!">
											</div>
										</a>
									</swiper-slide>
									<swiper-slide>
										<a href="#" class="card__link">

											<div class="card__image">
												<img src="@/assets/images/temp08.jpg" alt="힐링을 선물받다 펜캉스 고고!">
											</div>
										</a>
									</swiper-slide>
								</swiper>
							</div>
							<div class="progress">
								<div class="progress__bar progress__bar--1"></div>
							</div>
						</div>
					</div>
				</div>
				<!-- 겨울은 스키·보드의 계절! -->
				<div class="item">
					<!-- [REST] 관리자 페이지에서 타이틀 설정 -->
					<h2 class="title">
						겨울은 스키·보드의 계절!
						<a href="#" class="btn-more">
							MORE
						</a>
					</h2>
					<div class="item__body">
						<div class="carousel">
							<!-- [REST] 노출상품 관리자 페이지에서 설정 -->
							<div class="carousel__slider">
								<swiper :slides-per-view="1.4" :space-between="16">
									<swiper-slide>
										<a href="#" class="carousel__link">
											<div class="carousel__image carousel__image--fit">
												<img src="@/assets/images/tempsquare01.jpg" alt="역삼 벤">
											</div>
											<div class="carousel__info">
												<div class="carousel__slogan info-slogan">
													초보자도 부담없이 즐기는!
												</div>
												<h3 class="carousel__title info-title">
													[평창] 대원리조트 스키강습
												</h3>
												<div class="carousel__grad info-grad">
													<span class="carousel__icon info-icon">
														<i class="icon icon-star"></i>
													</span>
													<span class="carousel__gradnum info-gradnum">
														9.7
													</span>
													<span class="carousel__gradcounter info-gradcounter">
													2,400
												</span>
												</div>
												<div class="carousel__price">
													<span class="carousel__saleprice">
														28%
													</span>
													<span class="carousel__currentprice">
														<strong>49,800</strong>원
													</span>
												</div>
											</div>
										</a>
									</swiper-slide>
									<swiper-slide>
										<a href="#" class="carousel__link">
											<div class="carousel__image carousel__image--fit">
												<img src="@/assets/images/tempsquare01.jpg" alt="역삼 벤">
											</div>
											<div class="carousel__info">
												<div class="carousel__slogan info-slogan">
													환상적인 루프탑이 있어요
												</div>
												<h3 class="carousel__title info-title">
													역삼 벤
												</h3>
												<div class="carousel__grad info-grad">
													<span class="carousel__icon info-icon">
														<i class="icon icon-star"></i>
													</span>
													<span class="carousel__gradnum info-gradnum">
														9.7
													</span>
													<span class="carousel__gradcounter info-gradcounter">
													2,400
												</span>
												</div>
												<div class="carousel__price">
													<span class="carousel__saleprice">
														28%
													</span>
													<span class="carousel__currentprice">
														<strong>49,800</strong>원
													</span>
												</div>
											</div>
										</a>
									</swiper-slide>
								</swiper>
							</div>
						</div>
					</div>
				</div>
				<!-- 헬로스테이 추천 플레이스 -->
				<div class="item">
					<h2 class="title">
						헬로스테이 추천 플레이스
						<a href="#" class="btn-more">
							MORE
						</a>
					</h2>
					<div class="item__body">
						<div class="card">
							<a href="#" class="card__link">
								<div class="card__image">
									<img src="@/assets/images/temp08.jpg" alt="힐링을 선물받다 펜캉스 고고!">
								</div>
								<h2 class="card__title">
									<strong>소울 플레이스, 소울 맛집을 소개합니다.</strong>
									<i class="icon icon-func"></i>
								</h2>
								<p class="card__description">
									국내 감성 풀빌라 베스트 3
								</p>
							</a>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
	<TheFooter></TheFooter>
	<TheNavigation></TheNavigation>
</template>